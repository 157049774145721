import React from "react";

import { navigate, PageProps } from "gatsby";

import { Layout, Container } from "components/layout";
import { ViewHeader } from "components/view/ViewHeader";
import {
  Form,
  FormColumn,
  FormContent,
  FormFooter,
  FormRow,
  FormTextInput,
  FormTitle,
  FormButtonLink,
} from "components/common/Form";
import { useMessage } from "hooks/api/useMessage";
import { AdminSidebar } from "components/view/AdminSidebar";

interface AdminMessagePageProps extends PageProps {
  id: string;
}

export default function AdminMessagePage(props: AdminMessagePageProps) {
  const { id } = props;

  const message = useMessage(id);

  React.useEffect(() => {
    if (!message.isLoading && !message.data) navigate("/404");
  }, [message]);

  return (
    <Layout>
      <ViewHeader />
      <Container hasSidebar>
        <AdminSidebar {...props} />
        <Container noPadding>
          <Form>
            <FormTitle>Message</FormTitle>
            <FormContent>
              <FormRow>
                <FormColumn>
                  <FormTextInput
                    field="name"
                    label="Name"
                    disabled
                    defaultValue={message.data?.name}
                  />
                </FormColumn>
                <FormColumn>
                  <FormTextInput
                    field="email"
                    label="Email"
                    disabled
                    defaultValue={message.data?.email}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FormTextInput
                    type="textarea"
                    field="message"
                    label="Message"
                    defaultValue={message.data?.message}
                    disabled
                  />
                </FormColumn>
              </FormRow>
            </FormContent>
            <FormFooter>
              <FormButtonLink to="/administration/messages" label="Close" />
            </FormFooter>
          </Form>
        </Container>
      </Container>
    </Layout>
  );
}
